<!--推荐商品管理-->
<template>
  <div class="recommendList">
    <section class="table-search">
      <div class="search-L">
        <el-select
          v-model="tableParams.serviceId"
          placeholder="请选择服务内容"
          @change="goSelectServiceType"
          clearable
          class="mr15"
        >
          <el-option
            v-for="item in serviceList"
            :key="item.serviceId"
            :label="item.serviceName"
            :value="item.serviceId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="goClick(0)">添加推荐商品</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="商品缩略图" align="center">
        <template slot-scope="scope">
          <el-image
            style="width: 0.28rem; height: 0.28rem"
            :src="scope.row.imageUrl"
            :preview-src-list="[scope.row.imageUrl]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="商品名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商品库存" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.inventory || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商品分类" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsTypeName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="实际销量 / 虚拟销量" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.salesVolume || "0" }}</span>
          <span>&nbsp;/&nbsp;</span>
          <span>{{ scope.row.salesVolumeV || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="销售价格 / 划线价格" align="center">
        <template slot-scope="scope">
          <span>￥{{ scope.row.salesPrice || "0" }}</span>
          <span>&nbsp;/&nbsp;</span>
          <del>￥{{ scope.row.originalPrice || "0" }}</del>
        </template>
      </el-table-column>
      <el-table-column label="商品状态" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsStatusName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="medium"
            @click.stop="goClick(1, scope.row)"
            >移除</el-button
          >
          <el-button
            type="text"
            size="medium"
            @click.stop="goClick(2, scope.row)"
            >置顶</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.pageSize"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </section>
    <!-- 编辑弹框 -->
    <el-dialog
      title="添加推荐商品"
      :visible.sync="editShow"
      :close-on-click-modal="false"
      append-to-body
      width="400px"
    >
      <!-- 表单提交 -->
      <el-form
        :model="params"
        :rules="rules"
        ref="form-ref"
        label-width="100px"
      >
        <section class="form-main">
          <el-form-item label="已选服务内容">
            {{ tableParams.serviceName }}
          </el-form-item>
          <el-form-item label="选择商品" prop="orderGoodsIds">
            <el-select
              v-model="params.orderGoodsIds"
              placeholder="请选择商品"
              clearable
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in goodsList"
                :key="item.orderGoodsId"
                :label="item.goodsName"
                :value="item.orderGoodsId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </section>
      </el-form>
      <div class="form-footer">
        <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getGoodsListByServiceId,
  getRecommendList,
  goAddRecommendGoods,
  goTopRecommendGoods,
  goRemoveRecommendGoods,
} from "@/api/mall/mall";
import { getServiceList } from "@/api/resource/room";
import { rules } from "@/db/rules";
export default {
  name: "RecommendList",
  data() {
    return {
      rules,
      serviceList: [],
      tableParams: {
        merchantId: "",
        shopId: "",
        serviceId: "",
        serviceName: "",
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      currentPage: 1, // 当前页
      total: 0, // 总条数
      editShow: false,
      params: {
        orderGoodsIds: [],
      },
      goodsList: [],
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getServiceList();
    this.getRecommendList();
  },
  methods: {
    // 置顶商品
    goTopRecommendGoods(guideId) {
      let params = {
        guideId: guideId,
      };
      goTopRecommendGoods(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getRecommendList();
        }
      });
    },
    // 移除商品
    goRemoveRecommendGoods(guideId) {
      let params = {
        guideId: guideId,
      };
      goRemoveRecommendGoods(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getRecommendList();
        }
      });
    },
    // 添加推荐商品
    goAddRecommendGoods() {
      let params = {
        merchantId: this.tableParams.merchantId,
        serviceId: this.tableParams.serviceId,
        shopId: this.tableParams.shopId,
        orderGoodsIds: this.params.orderGoodsIds,
      };
      goAddRecommendGoods(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.params.orderGoodsIds = [];
          this.getRecommendList();
          this.editShow = false;
        }
      });
    },
    // 查询推荐商品列表
    getRecommendList() {
      let params = {
        merchantId: this.tableParams.merchantId,
        serviceId: this.tableParams.serviceId,
        shopId: this.tableParams.shopId,
        page: this.tableParams.page,
        pageSize: this.tableParams.pageSize,
      };
      getRecommendList(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 通过商品分类id查询商品
    getGoodsListByServiceId() {
      let params = {
        merchantId: this.tableParams.merchantId,
        serviceId: this.tableParams.serviceId,
        shopId: this.tableParams.shopId,
      };
      getGoodsListByServiceId(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.goodsList = res.data;
          this.editShow = true;
        }
      });
    },
    // 【请求】服务列表
    getServiceList() {
      let data = {
        shopId: this.tableParams.shopId,
      };
      getServiceList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.serviceList = res.data;
        }
      });
    },
    // 选择点单商品分类
    goSelectServiceType() {
      this.serviceList.forEach((item) => {
        if (item.serviceId == this.tableParams.serviceId) {
          this.tableParams.serviceName = item.serviceName;
        }
      });
      this.reload();
    },
    // 点击
    goClick(type, row) {
      switch (type) {
        case 0:
          if (this.tableParams.serviceId) {
            this.getGoodsListByServiceId();
          } else {
            this.$message({
              message: "请先选择服务内容",
              type: "error",
            });
          }
          break;
        case 1:
        case 2:
          let text = "";
          type == 1 ? (text = "移除") : (text = "置顶");
          this.$confirm(`你确定要${text}吗?`, text, {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            if (type == 1) {
              this.goRemoveRecommendGoods(row.guideId);
            } else {
              this.goTopRecommendGoods(row.guideId);
            }
          });
          break;
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.goAddRecommendGoods();
        } else {
          return false;
        }
      });
    },
    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getRecommendList();
    },
    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getRecommendList();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
.recommendList {
  padding: 0.15rem;
  .table-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.15rem;
  }

  .search-L {
    display: flex;
  }
  .table-footer {
    margin-top: 0.1rem;
    display: flex;
    justify-content: flex-end;
  }
}
.form-footer {
  display: flex;
  justify-content: flex-end;
}
</style>